import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Home from './Component/Home/Home'
import Fp2 from './Component/Home/Fp2'
import Fp3 from './Component/Home/Fp3'
import './App.scss'

import Chatgpt from './Page/Chatgpt/Chatgpt'
import Chatgpt1 from './Page/Chatgpt/custom'
import Landing from './Page/NewChats/LandingFile'
import videoVideoIdMainfile from './Component/VideoIdcapture'
import LoginPage from './Page/Login/Login'
import RgistrationPage from './Page/Login/Registration'
import LucaMessage from './Page/NewChats/PromptEditior'
import ForgetPassword from './Page/Login/ForgotPassward'
import ShareMessage from './Page/NewChats/ShareMessage'
// window.addEventListener("beforeunload", (ev) => 
// {  
//     ev.preventDefault();
//     return ev.returnValue = 'Are you sure you want to close?';
// });
export default class App extends Component {
  render() {
    return (
      <>
        <div>

          <>
            <Router>
              <Switch>
                <Route path="/login" exact component={LoginPage} />
                <Route path="/registration" exact component={RgistrationPage} />
                <Route path="/newchat" exact component={Chatgpt} />
                <Route path="/lucaMessage" exact component={LucaMessage} />
                <Route path="/resetpassword" exact component={ForgetPassword} />
                <Route path="/share/:id" exact component={ShareMessage} />


                <Route path="/resetpassword/:pathid" exact component={ForgetPassword} />
                <Route path="/" exact component={Landing} />
                {/* <Route path="/:id" exact component={Chatgpt} /> */}
                {/* <Route path="/:id" exact component={Chatgpt} /> */}
                <Route path="/chaast" exact component={Chatgpt1} />
              </Switch>
            </Router>
          </>

        </div>
      </>
    )
  }
}


