
import React, { createContext, useEffect, useState } from "react";

import axios from "axios";
export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
    const [emailAddress, setemailAddress] = useState(() => {
        const storedEmail = localStorage.getItem("lucaEmail");
        return storedEmail ? storedEmail : "";
    })
    const [loginStatus, setloginStatus] = useState(() => {
        const storedStatus = localStorage.getItem("Lucalogin");
        return storedStatus ? storedStatus : false;
    })
    const [UserName, setUserName] = useState(() => {
        const UserName = localStorage.getItem("LucaUserName");
        return UserName ? UserName : "";
    })

    const [searchText, setsearchText] = useState("")
    const removeUserDetails = () => {
        localStorage.removeItem("LucaUserName");
        localStorage.removeItem("lucaEmail");
        localStorage.removeItem("Lucalogin");
        localStorage.removeItem("darkMode");
    }
    const [chathistory, setchathistory] = useState([])
    const [messages, setMessages] = useState([]);
    const [lastUserMessage, setLastUserMessage] = useState(null)
    const [createuiId, setcreateuiId] = useState("")
    const [propmtmessage, setpropmtmessage] = useState("")
    const [darkMode, setDarkMode] = useState(
        () => JSON.parse(localStorage.getItem('darkMode')) || false
    );

    useEffect(() => {
        localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }, [darkMode]);


    useEffect(() => {

        setemailAddress(() => {
            const storedEmail = localStorage.getItem("lucaEmail");
            return storedEmail ? storedEmail : "";
        })
        setloginStatus(() => {
            const storedStatus = localStorage.getItem("Lucalogin");
            return storedStatus ? storedStatus : false;
        })
        setUserName(() => {
            const UserName = localStorage.getItem("LucaUserName");
            return UserName ? UserName : "";
        })

    }, [localStorage.getItem("lucaEmail"), localStorage.getItem("Lucalogin")])
    console.log("hey", emailAddress, loginStatus)

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {

        try {
            const response = await axios.get(`https://finacebackend.marketsverse.com/api/promptget`);
            // Set loading to false after data is fetched
            if (response?.data?.success) {
                setpropmtmessage(response?.data?.data?.data)
            }
            else {
                setpropmtmessage("")
            }
        } catch (err) {
            // Stop loading if there's an error
        }
    };

    return (
        <GlobalContext.Provider
            value={{
                emailAddress, setemailAddress, setloginStatus, setDarkMode,
                loginStatus, removeUserDetails, setchathistory, chathistory, darkMode,
                messages, setMessages, setsearchText, searchText, setUserName, UserName, setpropmtmessage, propmtmessage,
                lastUserMessage, setLastUserMessage, createuiId, setcreateuiId,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContextProvider;
