import React, { useState } from 'react';

import axios from 'axios';
import { pdfjs } from 'react-pdf';

// Set up the PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PDFChat = () => {
  const [pdfText, setPdfText] = useState('');
  const [query, setQuery] = useState('');
  const [chat, setChat] = useState([]);

  // Extract text from PDF
  const extractTextFromPDF = async (file) => {
    const loadingTask = pdfjs.getDocument(file);
    const pdf = await loadingTask.promise;
    let text = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageText = content.items.map((item) => item.str).join(' ');
      text += `\n\n--- Page ${i} ---\n${pageText}`;
    }

    setPdfText(text);
    return text;
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      const fileURL = URL.createObjectURL(file);
      await extractTextFromPDF(fileURL);
    } else {
      alert('Please upload a PDF file');
    }
  };

  // Handle user query submission
  const handleQuerySubmit = async (event) => {
    event.preventDefault();
    if (!query) return;

    // Add user's query to chat
    setChat((prevChat) => [...prevChat, { role: 'user', content: query }]);

    // Send the PDF text and user query to OpenAI
    const response = await getOpenAIResponse(query, pdfText);

    // Add OpenAI's response to chat
    setChat((prevChat) => [...prevChat, { role: 'assistant', content: response }]);
    setQuery('');
  };

  // Get response from OpenAI API
  const getOpenAIResponse = async (userQuery, pdfContent) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are an assistant analyzing a PDF document.' },
            { role: 'user', content: `PDF content:\n${pdfContent}\n\nQuestion: ${userQuery}` },
          ],
        },
        {
          headers: {
            Authorization: `Bearer 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`,
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error from OpenAI API:', error);
      return 'An error occurred while processing the query.';
    }
  };

  return (
    <div>
      <h2>PDF Chat</h2>
      <input type="file" accept="application/pdf" onChange={handleFileUpload} />

      {pdfText && (
        <div>
          <h3>Ask questions about the PDF:</h3>
          <form onSubmit={handleQuerySubmit}>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Type your question here"
            />
            <button type="submit">Ask</button>
          </form>
        </div>
      )}

      <div className="chat-container">
        {chat.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            <strong>{message.role === 'user' ? 'User' : 'Assistant'}:</strong> {message.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PDFChat;
